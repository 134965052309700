import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import { useEffect } from 'react'
import cn from 'classnames'

const SETTINGS_PATH = '/account/settings'
const SUPPORT_AND_FAQ_PATH = 'https://docs.crystalknows.com'
const PRIVACY_PATH = 'https://crystalknows.com/privacy'
const TERMS_PATH = 'https://crystalknows.com/tos'

const miniUtilityNavLinks = [
  {
    name: 'Settings',
    icon: Icons.Gear,
    link: SETTINGS_PATH,
  },
  {
    name: 'Support & FAQ',
    icon: Icons.Clipboard,
    link: SUPPORT_AND_FAQ_PATH,
    targetBlank: true,
  },
]

interface OwnProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  expanded: boolean
}

export default function MiniUtilityNav({ open, setOpen, expanded }: OwnProps) {
  useEffect(() => {
    if (!expanded) setOpen(false)
  }, [expanded, setOpen])

  if (!open || !expanded) return null

  return (
    <div
      className={cn(
        'tw-absolute',
        'tw-bg-secondary',
        'tw-text-sidebar-strong',
        'tw-p-4',
        'tw-bottom-7',
        'tw-rounded-md',
        {
          expanded,
        },
      )}
    >
      <div className={cn('tw-flex', 'tw-flex-col', 'tw-gap-4')}>
        {miniUtilityNavLinks.map((navLink, i) => (
          <a
            href={navLink.link}
            className={cn('tw-text-sidebar-strong')}
            key={`${navLink.name}-${i}`}
            target={navLink.targetBlank ? '_blank' : undefined}
          >
            <div
              className={cn(
                'tw-flex',
                'tw-text-sm',
                'tw-items-center',
                'tw-gap-2',
              )}
            >
              <Icon
                className={cn(
                  'icon',
                  'tw-text-sidebar-strong',
                  'tw-stroke-1',
                  'tw-w-4',
                  'tw-h-4',
                )}
                icon={navLink.icon}
              />
              <span className={cn('tw-text-sidebar-strong')}>
                {navLink.name}
              </span>
            </div>
          </a>
        ))}
        <div
          className={cn('tw-flex', 'tw-text-sm', 'tw-items-center', 'tw-gap-2')}
        >
          <Icon
            className={cn(
              'icon',
              'tw-text-sidebar-strong',
              'tw-stroke-1',
              'tw-w-4',
              'tw-h-4',
            )}
            icon={Icons.MinimalLock}
          />
          <div>
            <a
              className={cn('tw-text-sidebar-strong')}
              href={PRIVACY_PATH}
              target="_blank"
            >
              Privacy
            </a>{' '}
            &{' '}
            <a
              className={cn('tw-text-sidebar-strong')}
              href={TERMS_PATH}
              target="_blank"
            >
              Terms
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

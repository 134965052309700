'use client'
import React, { useEffect } from 'react'
import ReactFullStory, { FullStoryAPI } from 'react-fullstory'
import { USE_FULLSTORY } from '@premeeting/lib/constants'
import { usePathname } from 'next/navigation'
import useAuth from '@crystal-eyes/hooks/useAuth'

export default function FullStory({ unauthed }: { unauthed?: boolean }) {
  const pathname = usePathname()
  const shouldShow =
    !unauthed ||
    pathname?.startsWith('/login') ||
    pathname?.startsWith('/sign-up')
  const useFullstory = USE_FULLSTORY && shouldShow
  const { data: auth } = useAuth()

  useEffect(() => {
    if (auth?.jwtPayload && !auth.jwtPayload.impersonated_by && USE_FULLSTORY) {
      const jwtPayload = auth.jwtPayload

      try {
        FullStoryAPI('identify', jwtPayload.sub?.split(':')[1], {
          displayName: jwtPayload.name,
        })
      } catch (e) {}
    }
  }, [auth])

  return useFullstory ? <ReactFullStory org="5WWQV" /> : null
}

'use client'

import { useState } from 'react'
import Link from 'next/link'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import useMeV2 from '@crystal-eyes/hooks/user/useMeV2'
import IdentityCircle from '@crystal-eyes/premeeting/components/elements/Avatar/IdentityCircle'
import ActivityToken from './ActivityToken'
import LedgerDrawer from './LedgerDrawer'
import MiniUtilityNav from './MiniUtlityNav'
import cn from 'classnames'

export default function NavBottomSection({ expanded }: { expanded: boolean }) {
  const [isUtlityNavOpen, setIsUtilityNavOpen] = useState(false)
  const { data: me } = useMeV2()
  const [ledgerOpen, setLedgerOpen] = useState<boolean>(false)

  const isAdmin = me?.isOrgAdmin
  const personalSettingsPath = '/account/settings'

  const toggleCreditLedgerDrawer = () => {
    if (!isAdmin) return
    setLedgerOpen(!ledgerOpen)
  }

  return (
    <div
      className={cn(
        'tw-flex',
        'tw-flex-col',
        'tw-gap-4',
        'tw-pr-6',
        'tw-py-2',
        'tw-whitespace-nowrap',
        'tw-pl-2',
        expanded && ['tw-pl-2'],
      )}
    >
      {me?.identity && (
        <div
          className={cn(
            'tw-flex',
            'tw-flex-row',
            'tw-items-center',
            'tw-pl-0',
            {
              'tw-gap-3': expanded,
              'tw-gap-6': !expanded,
            },
          )}
        >
          <IdentityCircle
            photoUrl={me?.identity?.personalInfo?.photoUrl}
            r={16}
          />
          <Link
            href={personalSettingsPath}
            className={cn('tw-text-sidebar-strong', 'tw-break-words')}
          >
            {me.identity.personalInfo?.fullName}
          </Link>
        </div>
      )}
      <div
        className={cn(
          'tw-relative',
          'tw-flex',
          'tw-items-center',
          'tw-cursor-pointer',
          'hover:tw-underline',
          expanded && ['hover:tw-underline'],
          'tw-pl-0',
          'lg:tw-pl-2',
        )}
      >
        <MiniUtilityNav
          open={isUtlityNavOpen}
          setOpen={setIsUtilityNavOpen}
          expanded={expanded}
        />
        <span
          className={cn('tw-flex', 'tw-items-center')}
          onClick={() => setIsUtilityNavOpen(!isUtlityNavOpen)}
        >
          <Icon
            icon={Icons.Hamburger}
            className={cn(
              'tw-w-4',
              'tw-h-4',
              'tw-text-sidebar-strong',
              'tw-stroke-2',
            )}
          />
        </span>
        <button
          className={cn(
            { 'tw-hidden': !expanded },
            expanded && [
              'tw-cursor-pointer',
              'tw-block',
              'tw-text-sidebar',
              'tw-border-none',
              'tw-text-sm',
              'tw-bg-transparent',
              'hover:tw-underline',
            ],
          )}
          onClick={() => setIsUtilityNavOpen(!isUtlityNavOpen)}
        >
          More options
        </button>
      </div>
      <div>
        <Link
          href="/logout"
          className={cn(
            'tw-flex',
            'tw-items-center',
            'tw-pb-[46px]',
            'tw-pl-0',
            'tw-text-sm',
            'tw-text-sidebar',
            'tw-gap-2',
            'lg:tw-pl-2',
          )}
          prefetch={false}
        >
          <Icon
            icon={Icons.Download}
            className={cn(
              '-tw-rotate-90',
              'tw-h-4',
              'tw-w-4',
              'tw-text-sidebar-strong',
              'tw-stroke-2',
            )}
          />
          <span className={cn(!expanded && 'tw-hidden')}>Logout</span>
        </Link>
      </div>
    </div>
  )
}

'use client'
import styles from './IdentityCircle.module.css'
import classNamesBind from 'classnames/bind'
const cn = classNamesBind.bind(styles)

interface DefaultImageProps {
  size?: number
  width?: number
  height?: number
}

export default function DefaultImage({
  size,
  width,
  height,
}: DefaultImageProps) {
  const svgWidth = size || width
  const svgHeight = size || height
  return (
    <svg
      width={svgWidth || 32}
      height={svgHeight || 32}
      className={cn({ defaultImg: !size })}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {size ? (
        <circle cx="14" cy="14" r="14" className="tw-fill-avatar-bg" />
      ) : (
        <rect cx="100%" cy="100%" className="tw-fill-avatar-bg" />
      )}
      <path
        className="tw-fill-avatar-fg"
        d="M5.0075 19.8739C4.72764 20.0978 4.22389 20.3776 4 23.736C4 23.736 7 28.45 14 28.45C21 28.45 24.2061 23.55 24.2061 23.55C23.9822 20.0237 23.4225 20.0978 23.0867 19.8739C21.9112 19.0343 20.5679 18.4186 19.1126 18.1387L18.9447 18.3066C16.1461 20.9933 11.6683 20.9374 8.98157 18.1387C7.52628 18.4186 6.18293 19.0343 5.0075 19.8739Z"
      />
      <path
        className="tw-fill-avatar-fg"
        d="M19.2245 10.0787C19.2245 12.8773 17.4334 17.0193 14.019 17.0193C10.6047 17.0193 8.81357 12.8773 8.81357 10.0787C8.98149 7.22409 11.4443 5.04116 14.2989 5.20907C16.9856 5.32102 19.0566 7.44798 19.2245 10.0787Z"
      />
    </svg>
  )
}

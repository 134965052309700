'use client'

import React, { ButtonHTMLAttributes, useEffect, useState } from 'react'
import Loader from '@crystal-eyes/components/elements/Loader/Loader'
import cn from 'classnames'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'

export type ButtonTheme =
  | 'primary'
  | 'secondary'
  | 'marketing'
  | 'cancel-button'
  | 'destructive-button'
  | 'only-text'
  | 'sustainable'

const PRIMARY_THEMES = [
  'primary',
  'secondary',
  'marketing',
  'cancel-button',
  'destructive-button',
]

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode | string
  className?: string
  style?: React.CSSProperties
  loading?: boolean
  theme?: ButtonTheme
  successMessage?: string
  success?: boolean
}

export default function Button({
  children,
  className,
  style,
  loading,
  success,
  successMessage,
  theme = 'primary',
  ...props
}: ButtonProps) {
  const [successActive, setSuccessActive] = useState(false)
  useEffect(() => {
    if (success) {
      setSuccessActive(true)
      setTimeout(() => {
        setSuccessActive(false)
      }, 2000)
    }
  }, [success])

  const themeClasses = (theme: ButtonTheme | undefined) => {
    switch (theme) {
      case 'primary':
        return [
          'tw-bg-button-primary',
          'hover:tw-bg-button-primary-hovered',
          'tw-text-button-primary',
          'hover:tw-text-button-primary-hovered',
          'tw-border-none',
          'active:tw-bg-button-primary-active',
          'active:tw-text-button-primary-active',
        ]
      case 'marketing':
        return [
          'tw-bg-button-marketing',
          'hover:tw-bg-button-marketing-hovered',
          'tw-text-button-marketing',
          'hover:tw-text-button-marketing-hovered',
          'tw-border-none',
          'active:tw-bg-button-marketing-active',
        ]
      case 'secondary':
        return [
          'tw-bg-button-secondary',
          'hover:tw-bg-button-secondary-hovered',
          'tw-text-button-secondary',
          'hover:tw-text-button-secondary-hovered',
          'tw-border',
          'tw-border-button-secondary',
          'hover:tw-border-button-secondary-hovered',
          'active:tw-bg-button-secondary-active',
          'active:tw-text-button-secondary-active',
          'disabled:tw-bg-button-disabled',
        ]
      case 'cancel-button':
        return [
          'tw-bg-button-cancel',
          'hover:tw-bg-button-cancel-hovered',
          'tw-text-button-cancel',
          'hover:tw-text-button-cancel-hovered',
          'tw-border-none',
          'active:tw-bg-button-cancel-active',
        ]
      case 'destructive-button':
        return [
          'tw-bg-button-destructive',
          'hover:tw-bg-button-destructive-hovered',
          'tw-text-button-destructive',
          'hover:tw-text-button-destructive-hovered',
          'tw-border-none',
          'active:tw-bg-button-destructive-active',
        ]
      default:
        return ''
    }
  }
  if (successActive && successMessage) {
    return (
      <>
        <div className={cn('tw-flex', 'tw-mb-[0.5rem]')}>
          <Icon
            className={cn(
              'tw-h-9',
              'tw-w-9',
              'tw-text-button-success',
              'tw-z-10',
            )}
            icon={Icons.CheckmarkCircleMasked}
          />
          <div
            className={cn(
              'tw-font-bold',
              'tw-bg-button-secondary',
              'tw-border',
              'tw-border-solid',
              'tw-border-button-success',
              'tw-rounded-full',
              'tw-tracking-[0.16em]',
              'tw-px-[9px]',
              'tw-py-[5px]',
              'tw-pl-[30px]',
              'tw-h-[0.875rem]',
              'tw-text-[0.875rem]',
              'tw-text-button-secondary',
              'tw-text-center',
              'tw-uppercase',
              'tw-select-none',
              'tw-relative',
              'tw-overflow-hidden',
              'tw-left-[-1.4rem]',
              'tw-top-[0.3rem]',
            )}
          >
            {successMessage}
          </div>
        </div>
      </>
    )
  }

  if (PRIMARY_THEMES.includes(theme)) {
    return (
      <button
        className={cn(
          'tw-inline-block',
          'tw-rounded-full',
          'tw-uppercase',
          'tw-text-sm',
          'tw-tracking-wide',
          'tw-clear-both',
          'tw-px-4',
          'tw-py-2',
          'tw-text-nowrap',
          'tw-font-bold',
          'hover:tw-cursor-pointer',
          'hover:tw-no-underline',
          'tw-border-solid',
          'disabled:tw-bg-button-disabled',
          'disabled:tw-text-button-disabled',
          'disabled:tw-border-0',
          'disabled:tw-cursor-not-allowed',
          themeClasses(theme),
          className,
        )}
        style={style}
        {...props}
      >
        {loading ? <Loader size={16} /> : children}
      </button>
    )
  }
  if (theme === 'sustainable') {
    return (
      <button
        className={cn(
          'tw-inline-block',
          'tw-rounded-sm',
          'tw-border',
          'tw-font-normal',
          'tw-tracking-normal',
          'tw-clear-both',
          'tw-px-6',
          'tw-py-1',
          'tw-text-nowrap',
          'tw-normal-case',
          'tw-leading-5',
          'tw-border-solid',
          'tw-border-button-sustainable',
          'tw-text-button-sustainable',
          'tw-bg-button-sustainable',
          'hover:tw-border-button-sustainable-hovered',
          'hover:tw-cursor-pointer',
          'hover:tw-no-underline',
          'hover:tw-bg-button-sustainable-hovered',
          'active:tw-bg-button-sustainable-active',
          'active:tw-border-button-sustainable-active',
        )}
        style={style}
        {...props}
      >
        {loading ? <Loader size={16} /> : children}
      </button>
    )
  }
  if (theme === 'only-text') {
    return (
      <button
        className={cn(
          'tw-border-none',
          'tw-bg-transparent',
          'tw-mb-0',
          'tw-tracking-nomrmal',
          'tw-text-action',
          'tw-p-0',
          'tw-flex',
          'tw-gap-1',
          'tw-text-nowrap',
          'tw-items-center',
          'hover:tw-shadow-none',
          'hover:tw-cursor-pointer',
          'disabled:tw-bg-transparent',
          'disabled:tw-text-primary',
          'disabled:tw-cursor-not-allowed',
        )}
        style={style}
        {...props}
      >
        {loading ? <Loader size={16} /> : children}
      </button>
    )
  }
}
